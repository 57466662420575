export const formatResponseFollowedItem = <T extends IResponseFollowedItemEntity>(item: IResponseFollowedItem<T>): IModelFollowedItem => {
  const appConfig = useRuntimeConfig().public;
  const isEnableShortName = appConfig.APP_CUSTOMER?.enable_teams_short_name;
  let imageUrl: string | undefined;
  let identitiesId: string[] | undefined;

  switch (item.type) {
    case "identity_item":
      if (item.entity.content && "PosterH" in item.entity.content) {
        const posterH = item.entity.content.PosterH;
        imageUrl = Array.isArray(posterH) ? posterH[0]?.url : posterH;
      }
      if ("identityId" in item.entity) {
        identitiesId = item.entity.identityId;
      }
      break;

    case "team":
      if ("logo" in item.entity) {
        imageUrl = item.entity.logo;
      }
      if ("identities" in item.entity) {
        identitiesId = item.entity.identities?.map(identity => identity.type);
      }
      break;

    default:
      break;
  }

  return {
    id: item.entity._id,
    title: isEnableShortName && item.entity?.shortTitle ? item.entity.shortTitle : item.entity.title,
    type: item.type,
    imageUrl,
    identitiesId,
  };
};

export const formatModelFollowedItem = (item: TFollowItemType, type: TFollowType): IModelFollowedItem => {
  return {
    id: item.id,
    title: item.title,
    type,
    imageUrl: item.imageUrl,
  };
};
